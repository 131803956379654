import {Route} from 'react-router-dom';
import React, {Fragment, useState, useEffect, useCallback} from 'react';

import About from './pages/About.js';
import Ad from './pages/Ad.js';
import Changelog from './pages/Changelog.js';
import Contact from './pages/Contact.js';
import FrequentlyAskedQuestions from './pages/FrequentlyAskedQuestions.js';
import HomePage from './pages/HomePage.js';
import LoadingSpinner from './components/UI/LoadingSpinner';

function App() {

  const [resultAds, setResultAds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [makes, setMakes] = useState([]);

  const fetchMakesHandler = useCallback(async () => {
    setIsLoading(true);

    fetch("https://api.carcaine.net/v1/makes/search")
    .then( ( response ) => {
      return response.json(); 
    })
    .then((data) => { 
      setMakes(data.data.makes);
    })
    .catch((err) => console.error(err));
    setIsLoading(false);
  }, []);

  //run once when the application loads
  useEffect(() => {
    // 👇️ only runs once
    //console.log('useEffect ran1 ');
    //console.log(resultAds);
    fetchMakesHandler();
    
  }, [fetchMakesHandler]); // 👈️ empty dependencies array

  const fetchResultAdsDataHandler = (resultAdsData) => {
    const resultAds = {
      ...resultAdsData
    };
    //console.log(resultAds);
    setResultAds(resultAds);
  };

  return (
    <Fragment>
      {isLoading && <LoadingSpinner text="Searching..." />}
      <Route exact path="/">
        <HomePage fetchResultAdsDataHandler={fetchResultAdsDataHandler} resultAds={resultAds} makes={makes} isLoading={isLoading}/>
      </Route>
      <Route path="/about">
        <About />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
      <Route path="/changelog">
        <Changelog />
      </Route>
      <Route path="/frequently-asked-questions">
        <FrequentlyAskedQuestions />
      </Route>
      <Route path="/ad/:adId/:seoURL">
        <Ad />
      </Route>
    </Fragment>
  );
}

export default App;
