import React, {Fragment} from 'react';//, useState

import Header from '../components/Layout/Header';
import Home from '../components/Layout/Home';
import Results from '../components/Layout/Results';
import Footer from '../components/Layout/Footer';

const HomePage = (props) => {

    
  //const [resultAds, setResultAds] = useState([]);
  //const [makes, setMakes] = useState([]);
  //const [isLoading, setIsLoading] = useState(false);
/*
  //fetch the master makes list
  const fetchMakesHandler = useCallback(async () => {
    setIsLoading(true);

    fetch("https://api.carcaine.net/v1/makes/search")
    .then( ( response ) => {
      return response.json(); 
    })
    .then((data) => { 
      setMakes(data.data.makes);
    })
    .catch((err) => console.error(err));
    setIsLoading(false);
  }, []);

  //run once when the application loads
  useEffect(() => {
    // 👇️ only runs once
    //console.log('useEffect ran1 ');
    //console.log(resultAds);
    fetchMakesHandler();
    
  }, [fetchMakesHandler]); // 👈️ empty dependencies array
*/

/*
  const fetchResultAdsDataHandler = (resultAdsData) => {
    const resultAds = {
      ...resultAdsData
    };
    //console.log(resultAds);
    setResultAds(resultAds);
  };
  */

  return (
      <Fragment>
          {props.resultAds.data !== undefined && <Header /> }
          {!props.isLoading && props.resultAds.data === undefined  && <Home makes={props.makes} onSetResultAds={props.fetchResultAdsDataHandler} />}
          {props.resultAds.data !== undefined && <Results results={props.resultAds} makes={props.makes} onSetResultAds={props.fetchResultAdsDataHandler} />}
          <Footer />
      </Fragment>
  );
}

export default HomePage;