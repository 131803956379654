//import { Fragment } from "react";

import "./Pagination.css";

const Pagination = (props) => {
    //console.log(props);
    const paginationLinks=[];
    for(let i = 1; i <= props.totalPages; i++) {
        let currentClass = "pagination-button";
        if(props.currentPage === i) {
            currentClass = currentClass+" current-pagination-button";
        }
        paginationLinks.push(<li key={i}><button className={currentClass} onClick={props.onPaginationClickHandler} data-id={i}>{i}</button></li>)
    }

    return (
        <ul className="results-pagination">
        {paginationLinks}
        </ul>
    )
}
export default Pagination;