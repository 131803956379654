import { Fragment, useState, useEffect } from "react";

import SelectOption from './SelectOption';

import "./SearchBarSide.css";

const SearchBarSide = (props) => {
//const SearchBarSide = forwardRef(function SearchBarSide(props, ref) {

    //const {sortRef, sortChangeRef} = ref;

    const [models, setModels] = useState([]);
    const [selectedMake, setSelectedMake] = useState('');
    const [selectedModel, setSelectedModel] = useState('');
    const [selectedFromYear, setSelectedFromYear] = useState('');
    const [selectedToYear, setSelectedToYear] = useState('');
    const [selectedFromPrice, setSelectedFromPrice] = useState('');
    const [selectedToPrice, setSelectedToPrice] = useState('');
    const [selectedFuel, setSelectedFuel] = useState('');
    const [selectedTransmission, setSelectedTransmission] = useState('');
    const [enteredText, setEnteredText] = useState('');
    //const [sort, setSort] = useState('');
    
    //populate the makes dropdown - makes are passed in via props (from App.js)
    //populate the two years dropdowns
    const year_array = [];
    
    for(let year = new Date().getFullYear(); year >= 1940; year=year-1) {
        year_array.push(year);
    }		
    
    //populate the two prices dropdowns
    const price_array = [];
    price_array.push(500);
    for(let price = 1000; price <= 500000; price=price+1000) {
        price_array.push(price);

        if(price >= 100000) {
            price = price+24000;
        }
        else if(price >= 50000) {
            price = price+9000;
        }
        else if(price >= 35000) {
            price = price+4000;
        }
    }	
    //console.log(price_array);


    //change handler for the makes dropdown - fetch models
    const makeChangeHandler = (event) => {
        //console.log("Make Changed Side");
        setSelectedMake(event.target.value);
        const formValue = event.target.value;

        if(formValue === "") { //if the from price has not been selected
            document.getElementById('modelSide').disabled = true;
        }
        else {
            //document.getElementById('model').disabled = false;

            //get the models for the relevant make
            //setIsLoading(true);

            const modelsUrl = "https://api.carcaine.net/v1/models";
            const modelsDropdown = document.querySelector( "#modelSide" );
    
    
            const request_body = {
                make_id: formValue
            };
    
            const options = { 
                method: "POST", 
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request_body)
            };
          
            fetch(modelsUrl, options)
            .then( ( response ) => {
                return response.json(); 
            })
            .then((data) => { 
                setModels(data.data.models);
                modelsDropdown.disabled = false;
            })
            .catch((err) => console.error(err));
           
        }
    


        
    }

    //change handler for the models dropdown
    const modelChangeHandler = (event) => {
        //console.log("Model Changed");
        setSelectedModel(event.target.value);
    }


    //change handler for from year dropdown
        //remove/disable all other older years from the To Year dropdown
        //enable the To Year Dropdown
    const fromYearChangeHandler = (event) => {
        setSelectedFromYear(event.target.value);
        const formValue = event.target.value;
        
        //if the from year dropdown was changed back to "Choose Year", then reset the form
        if(formValue === "") { //if the from year has not been selected
            document.getElementById('yeartoSide').disabled = true;
            //document.getElementById("yeartoSide").selectedIndex = 0;
            //reset the selected To Year
            setSelectedToYear("");
        }
        else {
            //else enable the TO year dropdown and disable years previous to the selected from year
            document.getElementById('yeartoSide').disabled = false;
            document.querySelectorAll("#yeartoSide option").forEach(opt => {
                opt.disabled = false;
            });
        }

        //disable all years that are before the selected from year.
        document.querySelectorAll("#yeartoSide option").forEach(opt => {
            if((parseInt(opt.value, 10) < parseInt(formValue, 10)) || (opt.value === "") ) {
                opt.disabled = true;
            }
        });
        //reset the selected To Year
        setSelectedToYear("");

    }

    const toYearChangeHandler = (event) => {
        setSelectedToYear(event.target.value);
    }

    //change handler for from price dropdown
        //remove/disable all other lower prices from the To Price dropdown
        //enable the To Price Dropdown
    const fromPriceChangeHandler = (event) => {
        setSelectedFromPrice(event.target.value);
        const formValue = event.target.value;
        
       
        //if the from price dropdown was changed back to "Choose Price", then reset the form
        if(formValue === "") { //if the from price has not been selected
            document.getElementById('pricetoSide').disabled = true;
            //document.getElementById("pricetoSide").selectedIndex = 0;
            //reset the selected To Price
            setSelectedToPrice("");
        }
        else {
            //else enable the TO price dropdown and disable prices below the selected from price
            document.getElementById('pricetoSide').disabled = false;
            document.querySelectorAll("#pricetoSide option").forEach(opt => {
                opt.disabled = false;
            });
        }

        //disable all prices that are below the selected from price.
        document.querySelectorAll("#pricetoSide option").forEach(opt => {
            //if((parseInt(opt.value, 10) < parseInt(formValue, 10)) || (opt.value === "") ) {
            if((parseInt(opt.value, 10) < parseInt(formValue, 10)) ) {
                opt.disabled = true;
            }
        });
        //document.getElementById("pricetoSide").selectedIndex = 0;
        //reset the selected To Price
        setSelectedToPrice("");
    }
    
    const toPriceChangeHandler = (event) => {
        setSelectedToPrice(event.target.value);
    }

    const transmissionChangeHandler = (event) => {
        setSelectedTransmission(event.target.value);
    }

    const fuelChangeHandler = (event) => {
        setSelectedFuel(event.target.value);
    }

    const textInputChangeHandler = (event) => {
        setEnteredText(event.target.value);
    }
/*
    const sortChangeHandler = (event) => {
        setSort(event.target.value);
    }
*/

    const searchAgainClickHandler = (event) => {
        event.target.style.display = "none";
        //document.getElementById('search_form').style.display = "flex";
        document.getElementById('search_form').classList.remove("hidden-form");
    }
    //submitHandler
        //validate the form
        //send the async query to the api
        //shift the layout
    const formSubmitHandler = (event) => {
        event.preventDefault(); 
        //setSort(document.getElementById('sort').value);
        /* 
        Start Swap Styles for mobile "Search Again" Button 
        */
        document.getElementById('search-again').style.display = "flex";
        document.getElementById('search_form').classList.add("hidden-form");
        //document.getElementById('search_form').style.display = "none";
        /* 
        Start Swap Styles for mobile "Search Again" Button 
        */

        let errorFree = true;
        if(selectedMake === "") {
            //console.log("make is empty");
            document.getElementById('makeSide').classList.add("error");
            errorFree=false;
        }
        else {
            document.getElementById('makeSide').classList.remove("error");
        }
        if(selectedModel === "") {
            //console.log("model is empty");
            document.getElementById('modelSide').classList.add("error");
            errorFree=false;
        }
        else {
            document.getElementById('modelSide').classList.remove("error");
        }
        if(selectedFromYear !== "" && selectedToYear === "") {
            //console.log("To Year is empty");
            document.getElementById('yeartoSide').classList.add("error");
            errorFree=false;
        }
        else {
            document.getElementById('yeartoSide').classList.remove("error");
        }
        if(selectedFromPrice !== "" && selectedToPrice === "") {
            //console.log("To Price is empty");
            document.getElementById('pricetoSide').classList.add("error");
            errorFree=false;
        }
        else {
            document.getElementById('pricetoSide').classList.remove("error");
        }
        if(enteredText !== "" && enteredText.length < 3) {
            //console.log("To Price is empty");
            document.getElementById('textSearchSide').classList.add("error");
            errorFree=false;
        }
        else {
            document.getElementById('textSearchSide').classList.remove("error");
        }
       /*
        if($("#search_form input[type=checkbox]:checked").length == 0) {
            error_free=false;

            //need to add some logic here if no country is selected. Fall back on IE?? Or Geo located option?
        }*/
        
        
        if (errorFree){
            //console.log(props);
            props.setIsLoading(true);
            //console.log("setIsLoading true");

            const resultsUrl = "https://api.carcaine.net/v1/results";
            const request_body = {
                "make":selectedMake,
                "model":selectedModel,
                "from_year":selectedFromYear,
                "to_year":selectedToYear,
                "from_price":selectedFromPrice,
                "to_price":selectedToPrice,
                "transmission":selectedTransmission,
                "fuel":selectedFuel,
                "location":"IRL",
                "text":enteredText
            }
            //console.log(request_body);
            const options = { 
                method: "POST", 
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request_body)
            };

            fetch(resultsUrl, options)
            .then( ( response ) => {
                return response.json(); 
            })
            .then((data) => { 
                //console.log(data);
                props.onSetResultAds(data);
                props.setIsLoading(false);
                //console.log("setIsLoading false");
                /**
                 * figure out how to pass in the results or results setter function from App.js (via props) to update the main array.
                 * Also, when searching for Honda Civic (all years) - there is an internal server error for "Error: Ad Mileage Unit error." --- check the API.
                 */
                //setResults(data.data.models);
                //modelsDropdown.disabled = false;
            })
            .catch((err) => console.error(err));
        }
        
        
    }
   // console.log (props);

   //console.log(props.queryData);
   useEffect(() => {
        //Set the make
        setSelectedMake(props.queryData.make);
        //Get the relevant Models
        const modelsUrl = "https://api.carcaine.net/v1/models";
        const modelsDropdown = document.querySelector( "#modelSide" );
        const yearToDropdown = document.querySelector( "#yeartoSide" );
        const priceToDropdown = document.querySelector( "#pricetoSide" );

        const request_body = {
            make_id: props.queryData.make
        };

        const options = { 
            method: "POST", 
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request_body)
        };
        
        fetch(modelsUrl, options)
        .then( ( response ) => {
            return response.json(); 
        })
        .then((data) => { 
            setModels(data.data.models);
            modelsDropdown.disabled = false;
            //set the selected model
            setSelectedModel(props.queryData.model);
        })
        .catch((err) => console.error(err));

       
        setSelectedFromYear(props.queryData.from_year);
        if(props.queryData.to_year !== "") {
            setSelectedToYear(props.queryData.to_year);
            yearToDropdown.disabled = false;
        }
        setSelectedFromPrice(props.queryData.from_price);
        setSelectedToPrice(props.queryData.to_price);
        if(props.queryData.to_price !== "") {
            setSelectedToPrice(props.queryData.to_price);
            priceToDropdown.disabled = false;
        }
        setSelectedTransmission(props.queryData.transmission);
        //setEnteredText(props.queryData.text);
    }, [setSelectedMake, 
        setSelectedModel, 
        setSelectedFromYear, 
        setSelectedToYear, 
        setSelectedFromPrice, 
        setSelectedToPrice, 
        setSelectedTransmission,
        props.queryData.make,
        props.queryData.model,
        props.queryData.from_year,
        props.queryData.to_year,
        props.queryData.from_price,
        props.queryData.to_price,
        props.queryData.transmission]); // 👈️ empty dependencies array

/*
<div className="select">
                        <select id="sort" name="sort"  onChange={sortChangeHandler} value={sort} ref={sortChangeRef}>
                            <option value="">Most Recent Ad</option>
                            <option value="yeardsc">Youngest</option>
                            <option value="yearasc">Oldest</option>
                            <option value="priceasc">Lowest Price</option>
                            <option value="pricedsc">Highest Price</option>
                            <option value="mileageasc">Lowest Mileage</option>
                            <option value="mileagedsc">Highest Mileage</option>
                        </select>
                    </div>
*/

   return (
        <Fragment>
            <div id="search-bar-side">
                <div id="search-again-button">
                    <button id="search-again" onClick={searchAgainClickHandler}>Search Again</button>
                </div>
                <form id="search_form" className="hidden-form" onSubmit={formSubmitHandler}>
                    <div id="make-model-side">
                    <div className="select">
                        <select id="makeSide" name="make" className="" onChange={makeChangeHandler} value={selectedMake}>
                        <option value="">Choose Make</option>
                        <optgroup label="Most searched for">
                           
                            {   //console.log(props.makes)
                                props.makes.map(make => {
                                //the only way to carry out logic inside a map function is to return the output via a ternary if statement as far as I can make out.
                                return make.most_searched === 1 ? 
                                    <SelectOption key={Math.random()} id={make.id} data-id={make.id} title={make.title} />
                                : null
                                   
                                }) 
                            }
                        </optgroup>
                        <optgroup label="All makes">
                            {props.makes.map(make => ( 
                                <SelectOption key={make.id} id={make.id} data-id={make.id} title={make.title} />
                            ))}
                        </optgroup>
                        </select>
                    </div>
                    <div className="select">
                        <select id="modelSide" name="model" disabled="disabled" onChange={modelChangeHandler} value={selectedModel}>
                        <option value="">Choose Model</option>
                        {models.map(model => ( 
                            <SelectOption key={model.id} id={model.id} data-id={model.id} title={model.title} />
                        ))}
                        </select>
                    </div>
                    </div>
                    <div className="select">
                        <select id="yearfromSide" name="yearfrom" onChange={fromYearChangeHandler} value={selectedFromYear}>
                            <option value="">From Year</option>
                            {year_array.map(year => ( 
                                <SelectOption key={year} id={year}  data-id={year} title={year} />         
                            ))}
                        </select>
                    </div>
                    <div className="select">
                        <select id="yeartoSide" name="yearto" disabled="disabled" onChange={toYearChangeHandler} value={selectedToYear}>
                            <option value="">To Year</option>
                            {year_array.map(year => ( 
                                <SelectOption key={year} id={year} data-id={year} title={year} />         
                            ))}  
                        </select>
                    </div>
                    <div className="select">
                        <select id="pricefromSide" name="pricefrom" onChange={fromPriceChangeHandler} value={selectedFromPrice}>
                            <option value="">From Price</option>
                            {price_array.map(price => ( 
                                <SelectOption key={price} id={price} data-id={price} title={price.toLocaleString("en-IE")} />
                                    
                            ))}
                        </select>
                    </div>
                    <div className="select">
                        <select id="pricetoSide" name="priceto" disabled="disabled" onChange={toPriceChangeHandler} value={selectedToPrice}>
                            <option value="">To Price</option>
                            {price_array.map(price => ( 
                                <SelectOption key={price} id={price} data-id={price} title={price.toLocaleString("en-IE")} />
                                    
                            ))}
                        </select>
                    </div>
                    <div className="select">
                        <select id="fuelSide" name="fuel" onChange={fuelChangeHandler} value={selectedFuel}>
                        <option value="">Fuel</option>
                        <option value="any">Any</option>
                        <option value="Petrol">Petrol</option>
                        <option value="Petrol Hybrid">Petrol Hybrid</option>
                        <option value="Petrol Plugin-in Hybrid">Petrol Plug-in Hybrid</option>
                        <option value="Diesel">Diesel</option>
                        <option value="Diesel Hybrid">Diesel Hybrid</option>
                        <option value="Diesel Plugin-in Hybrid">Diesel Plug-in Hybrid</option>
                        <option value="Electric">Electric</option>
                        </select>
                    </div>
                    <div className="select">
                        <select id="transmissionSide" name="transmission" onChange={transmissionChangeHandler} value={selectedTransmission}>
                        <option value="">Transmission</option>{" "}
                        <option value="any">Any</option>{" "}
                        <option value="manual">Manual</option>{" "}
                        <option value="automatic">Automatic</option>
                        </select>
                    </div>
                    <div className="input">
                        <input id="textSearchSide" name="textSearch" onChange={textInputChangeHandler} placeholder="Text Search" value={enteredText} />
                    </div>
                    
                    <div className="input-button">
                        <input
                        type="submit"
                        value="Find your new car!"
                        id="search_submit"
                        />
                    </div>
                </form>
            </div>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7919601831039622" crossOrigin="anonymous"></script>
            <ins className="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-7919601831039622"
                data-ad-slot="5653463614"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
        </Fragment>
    );
};
//});

export default SearchBarSide;
