import React, {Fragment, useEffect, useState} from 'react';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';
import StatusTable from '../components/UI/StatusTable';

const Changelog = () => {

    const [pageContent, setPageContent] = useState('');
  
    useEffect(() => {
        const pageUrl = "https://cms.carcaine.net/wp-json/wp/v2/pages/105";
                
        const options = { 
            method: "GET", 
            cache: "no-cache"
        };
    
        fetch(pageUrl, options)
            .then( ( response ) => {
                return response.json(); 
            })
            .then((data) => { 
                //console.log(data.content.rendered);
                setPageContent(data.content.rendered);
            })
            .catch((err) => console.error(err));

    });

    return (
        <Fragment>
            <Header />
            <div className='page-content'>
                <div className="wp-block-cover">
                    <span aria-hidden="true" className="wp-block-cover__background has-background-dim"></span>
                    <img decoding="async" loading="lazy" className="wp-block-cover__image-background wp-image-80 img-responsive" alt="" src="https://cms.carcaine.net/wp-content/uploads/2022/10/home-bg.jpg" data-object-fit="cover" srcSet="https://cms.carcaine.net/wp-content/uploads/2022/10/home-bg.jpg 1500w, https://cms.carcaine.net/wp-content/uploads/2022/10/home-bg-300x217.jpg 300w, https://cms.carcaine.net/wp-content/uploads/2022/10/home-bg-1024x741.jpg 1024w, https://cms.carcaine.net/wp-content/uploads/2022/10/home-bg-768x556.jpg 768w" sizes="(max-width: 1500px) 100vw, 1500px" width="1500" height="1086" />
                    <div className="wp-block-cover__inner-container">
                        <div className="wp-block-image">
                            <figure className="aligncenter size-full"><img decoding="async" loading="lazy" src="https://cms.carcaine.net/wp-content/uploads/2022/10/logo.png" alt="" className="wp-image-81 img-responsive" srcSet="https://cms.carcaine.net/wp-content/uploads/2022/10/logo.png 364w, https://cms.carcaine.net/wp-content/uploads/2022/10/logo-300x82.png 300w" sizes="(max-width: 364px) 100vw, 364px" width="364" height="99" /></figure>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div style={{height:"50px"}} aria-hidden="true" className="wp-block-spacer"></div>
                    <h1>Integrated Site Status</h1>
                    <p>Here is a list of sites that are integrated, their current status and integration level.</p>
                <StatusTable />
                </div>
            </div>
            <div className="page-content" dangerouslySetInnerHTML={{__html: pageContent}}></div>
            <Footer />
        </Fragment>
    );
}

export default Changelog;