import React, {useState, useEffect, Fragment} from 'react';
import {Link, useParams} from 'react-router-dom'; //useParams, 

import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';
//import LoadingSpinner from '../components/UI/LoadingSpinner';

import "../components/UI/Ad.css";

/**
 * This function was originally written to use location.state as passed in from the ad clicked in result.js
 * Modified to load the ad details from API based on the Ad ID in the IRL (params).
 */

const Ad = () => {
    const params = useParams();
    //console.log(window.location.href);
    //const fbShareURL = "https://www.facebook.com/sharer/sharer.php?u="+encodeURIComponent(window.location.href);
    const [adAttributes, setAdAttributes] = useState('');
    const [adDetails, setAdDetails] = useState([]);
    const [fbShareURL, setFbShareURL] = useState('');
    const [twitterText, setTwitterText] = useState('');
    const [duplicateUrls, setDuplicateUrls] = useState([]);
    let clickIntercepted = false;

    //one time
    useEffect(() => {
        let result = "";
        
        const resultsUrl = "https://api.carcaine.net/v1/results/"+params.adId;//+location.
            
        const options = { 
            method: "GET", 
            cache: "no-cache"
        };

        fetch(resultsUrl, options)
        .then( ( response ) => {
            return response.json(); 
        })
        .then((data) => { 
            result = data.data.ads[0];
            setAdDetails(result);
            //setFbShareURL("https://www.facebook.com/sharer/sharer.php?u="+encodeURIComponent(window.location.href));
            setFbShareURL("https://www.facebook.com/sharer/sharer.php?u="+window.location.href);
            setTwitterText("https://twitter.com/intent/tweet?text="+encodeURIComponent("I found this "+result.make+" "+result.model+" on @CarcaineNet - check it out: "+window.location.href));
            
            //console.log(JSON.parse(result.duplicate_urls ));
            setDuplicateUrls(JSON.parse(result.duplicate_urls ));
            
            let tempAdAttributes = [];
            
            if(result.year !== null && result.year !== "" && result.year !== undefined) {
                tempAdAttributes.push(result.year);
            }

            if(result.mileage !== null && result.mileage !== "" && result.mileage !== undefined) {
                tempAdAttributes.push(result.mileage);
            }

            if(result.engine_size !== null && result.engine_size !== "" && result.engine_size !== undefined) {
                tempAdAttributes.push(result.engine_size);
            }

            if(result.fuel_type !== null && result.fuel_type !== "" && result.fuel_type !== undefined) {
                tempAdAttributes.push(result.fuel_type);
            }

            if(result.transmission !== null && result.transmission !== "" && result.transmission !== undefined) {
                tempAdAttributes.push(result.transmission);
            }

            if(result.seller_type !== null && result.seller_type !== "" && result.seller_type !== undefined) {
                tempAdAttributes.push(result.seller_type);
            }

            if(result.registration !== null && result.registration !== "" && result.registration !== undefined) {
                tempAdAttributes.push(result.registration);
            }

            setAdAttributes(" "+tempAdAttributes.join(" | "));

            //const links = duplicateUrls.map((url, index) => {
                //console.log(duplicateUrls);
            //});
        })
        .catch((err) => console.error(err));
        
       
        //setAdDetails(result);
        //console.log(result);
        
    }, [setAdDetails, params]);//setupAdAttributes, , result

    const handleAdLinkClick = (event) => {

        if(clickIntercepted) {
            clickIntercepted=false;//reset it back to false so that we can process the next click!
            return;
        }
        event.preventDefault(); 
        console.log(event);
        const resultsUrl = "https://api.carcaine.net/v1/stats/outbound";
        const request_body = {
            //"destination_site":adDetails.site_name,
            "destination_site":event.target.dataset.siteName,
            //"destination_url":adDetails.url,
            "destination_url":event.target.href,
            "member_status":"0",
            "make":adDetails.make,
            "model":adDetails.model,
            "click_source":"site"
        }
            
        console.log(request_body);
        const options = { 
            method: "POST", 
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request_body)
        };

        fetch(resultsUrl, options)
        .then( ( response ) => {
            return response.json(); 
        })
        .then((data) => { 
            clickIntercepted=true;//set a flag to say that the click has been intercepted before triggering a click event again - this is to prevent an infinite loop of click intercepts and resumes.
            document.getElementById(event.target.id).click();
        })
        .catch((err) => console.error(err));
    }

    return (
        <Fragment>
            <Header />
            <div className='container'>
                <div className='adNav'>
                    <Link to="/">Back to Results</Link> 
                </div>
                <div className="site-container" >
                    <div className="result-ad">
                    <div
                        className="result-thumb"
                        style={{'backgroundImage': 'url('+adDetails.thumbnail+')'}}
                    ></div>
                    <div className="result-details">
                        <div className="results-main">
                            <div className="result-heading">
                                <span className="ad-sub-title"><h2>{adDetails.make} - {adDetails.model}</h2></span>
                                <span className="ad-title"><h1>{adDetails.title}</h1></span>
                                <span className="ad-price">Price: {adDetails.currency} {adDetails.price}</span>
                            </div>
                            <div className="result-attribs">
                                {adAttributes}
                            </div>
                            <div className="result-description">
                                {adDetails.description}
                            </div>
                            <div className="result-loc">
                                <span className="ad-location">{adDetails.location}</span>
                                <span className="ad-date">{adDetails.date}</span>
                            </div>
                            <div className='social-share'>
                                <a href={twitterText} className="twitter-share-button" data-show-count="false" title='Tweet this Ad' target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="30px">{/* Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}<path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/></svg></a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
                                <a href={fbShareURL} target="_blank" rel="noopener noreferrer" title="Share Ad on Facebook"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="30px">{/* Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}<path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"/></svg></a>
                            </div>
                        </div>
                        <div className="result-from">
                            <a key={Math.random()} id={Math.random()} className="ad-site" onClick={handleAdLinkClick} href={adDetails.url} target="_blank" rel="noreferrer" data-site-name={adDetails.site_name}>View on {adDetails.site_name}</a>
                            {duplicateUrls.map((link, index) => (// 
                                <a key={Math.random()} id={Math.random()} className="ad-site" onClick={handleAdLinkClick} href={link.url} target="_blank" rel="noreferrer" data-site-name={link.site_name}>View on {link.site_name}</a>
                            ))}
                        
                        </div>
                    </div>
                    </div>
                </div>
                <div className='adNav'>
                    <Link to="/">Back to Results</Link> 
                </div>
            </div>
            <Footer />
        </Fragment>
        
    );
};

export default Ad;