//import { Fragment } from "react";
import unicorn from '../../assets/img/unicorn.jpg';

import "./UnicornNotice.css";

const UnicornNotice = () => {
    

    return (
        <div id="unicorn-notice">
            <div>
                <h1>Sorry - no results found!</h1>
                <p><b>You may be searching for a Unicorn.</b><br/>If you have filters selected, please try relaxing your search terms:</p>
                <ul>
                    <li>If you have selected a year range, try selecting a larger year range.</li>
                    <li>If you have selected a price range, try selecting a larger price range (or try removing it altogether).</li>
                    <li>If you have selected a transmission type, try removing the selection.</li>
                    <li>If you have entered a text search, try changing or removing the text search filter.</li>
                </ul>
                <p><b>If you still can't find what you are looking for...</b><br/>
                The vehicle you are looking for may not be on the market at the moment. This is often the case with older and rarer cars.<br/><b><i>Register with us to receive an alert for the next time your dream car comes on to the market.</i></b></p>
            </div>
            <div className="unicorn-holder">
                <img src={unicorn} alt="Unicorn saying well F#?*! because it can't find the car of it's dreams." title="Looking for a bit of a Unicorn, eh? You'll need to hold on to your trike for another while yet." />
            </div>
        </div>
    )
}
export default UnicornNotice;