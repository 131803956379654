import { Fragment, useState, useEffect } from "react";
import {Link } from 'react-router-dom';

import "./Result.css";

const Result = (props) => {
  //generate the outbound link
  //assemble the attributes
  const [adAttributes, setAdAttributes] = useState('');
  
  //console.log(props);
  useEffect(() => {
    //console.log(props);
    let tempAdAttributes = [];
    //searchAttributes.push(props.results.data.display_vars.make+" "+props.results.data.display_vars.model);

    if(props.year !== null && props.year !== "" && props.year !== undefined) {
      tempAdAttributes.push(props.year);//+" | ";
      //setAttributesCar(attributesCar+" | "+props.results.data.query_data.from_year+" - "+props.results.data.query_data.to_year);
    }

    if(props.mileage !== null && props.mileage !== "" && props.mileage !== undefined) {
      //youSearchedFor += " | <span>Price:</span> "+response.data.query_data.from_price+" - "+response.data.query_data.to_price;
      tempAdAttributes.push(props.mileage+" "+props.mileage_unit); //+" | ";
      //setAttributesCar(attributesCar+" | "+props.results.data.query_data.from_price+" - "+props.results.data.query_data.to_price);
    }

    if(props.engine_size !== null && props.engine_size !== "" && props.engine_size !== undefined) {
      tempAdAttributes.push(props.engine_size);
    }

    if(props.fuel_type !== null && props.fuel_type !== "" && props.fuel_type !== undefined) {
      tempAdAttributes.push(props.fuel_type);
    }

    if(props.transmission !== null && props.transmission !== "" && props.transmission !== undefined) {
      tempAdAttributes.push(props.transmission);
    }

    if(props.seller_type !== null && props.seller_type !== "" && props.seller_type !== undefined) {
      tempAdAttributes.push(props.seller_type);
    }

    if(props.registration !== null && props.registration !== "" && props.registration !== undefined) {
      tempAdAttributes.push(props.registration);
    }

    setAdAttributes(" "+tempAdAttributes.join(" | "));
  }, [setAdAttributes, adAttributes, props]);

//<Link to={'/ad/'+props.adID+'/'+props.make+'-'+props.model} state={props}>
  return (
    <Fragment>
      <Link to={{
        pathname: '/ad/'+props.adID+'/'+props.make+'-'+props.model,
        state: {
          result: props
        }
      }} >
      <div className="site-container" >
        <div className="result-ad">
          <div
            className="result-thumb"
            style={{'backgroundImage': 'url('+props.thumbnail+')'}}
          ></div>
          <div className="result-details">
            <div className="results-main">
              <div className="result-heading">
                <span className="ad-sub-title">{props.make} - {props.model}</span>
                <span className="ad-title">{props.title}</span>
                <span className="ad-price">{props.currency} {props.price}</span>
              </div>
              <div className="result-attribs">
                {adAttributes}
              </div>
            
            </div>
            <div className="result-from">
              <span className="ad-site">View Details</span>
            </div>
          </div>
        </div>
      </div>
      </Link>
    </Fragment>
  );
};

export default Result;
