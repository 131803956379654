import React, {Fragment, useEffect, useState} from 'react';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';

const About = () => {

    const [pageContent, setPageContent] = useState('');
  
    useEffect(() => {
        const aboutUrl = "https://cms.carcaine.net/wp-json/wp/v2/pages/83";
                
        const options = { 
            method: "GET", 
            cache: "no-cache"
        };
    
        fetch(aboutUrl, options)
            .then( ( response ) => {
                return response.json(); 
            })
            .then((data) => { 
                //console.log(data.content.rendered);
                setPageContent(data.content.rendered);
            })
            .catch((err) => console.error(err));
        });

    return (
        <Fragment>
            <Header />
            <div className="page-content" dangerouslySetInnerHTML={{__html: pageContent}}></div>
            <Footer />
        </Fragment>
    );
}

export default About;