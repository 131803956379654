import { Fragment, useState } from "react";

import SearchBar from '../UI/SearchBar';
import LoadingSpinner from '../../components/UI/LoadingSpinner';

import homeLogo from "../../assets/img/logo_home.png";
import "./Home.css";

const Home = (props) => {

    const [isLoading, setIsLoading] = useState(false);
   
    return (
        <Fragment>
            {isLoading && <LoadingSpinner text="Searching..." />}
            <div className="post-content home">
                
                <img
                    decoding="async"
                    src={homeLogo}
                    alt="Carcaine Logo"
                    className="home-logo img-responsive"
                />
                
                <p className="home-intro-text white">
                We search all of the car sites – so you don’t have to. Find your dream car today.
                </p>

                <SearchBar makes={props.makes} onSetResultAds={props.onSetResultAds} setIsLoading={setIsLoading} />
                
            </div>
        </Fragment>
    );
};

export default Home;
