import React, {Fragment, useState} from 'react';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';
import LoadingSpinner from '../components/UI/LoadingSpinner';

import './Contact.css';

const About = (props) => {

    const [selectedSubject, setSelectedSubject] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [optin, setOptIn] = useState('');
    const [formSending, setFormSending] = useState(false);
    const [formSent, setFormSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const subjectChangeHandler = (event) => {
        setSelectedSubject(event.target.value);
    }
    
    const nameChangeHandler = (event) => {
        setName(event.target.value);
    }

    const emailChangeHandler = (event) => {
        setEmail(event.target.value);
    }

    const messageChangeHandler = (event) => {
        setMessage(event.target.value);
    }

    const optinChangeHandler = (event) => {
        setOptIn(event.target.value);
    }


    
    const formSubmitHandler = (event) => {
        event.preventDefault();
        
        let errorFree = true;
        if(selectedSubject === "") {
            document.getElementById('your-subject').classList.add("error");
            errorFree=false;
        }
        else {
            document.getElementById('your-subject').classList.remove("error");
        }
       
        if(name === "") {
            document.getElementById('your-name').classList.add("error");
            errorFree=false;
        }
        else {
            document.getElementById('your-name').classList.remove("error");
        }
        if(email !== "" && email.match(/^[a-zA-Z][a-zA-Z0-9\-_.]+@[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}$/)) {
        
            document.getElementById('your-email').classList.remove("error");
            
        }
        else {
            document.getElementById('your-email').classList.add("error");
            errorFree=false;
        }
        
        if(message === "") {
            document.getElementById('your-message').classList.add("error");
            errorFree=false;
        }
        else {
            document.getElementById('your-message').classList.remove("error");
        }


        let optingIn = false;
        if(optin !== "") {
            optingIn = true;
        }

        if (errorFree){
            setIsLoading(true);
            setFormSending(true);

            const resultsUrl = "https://api.carcaine.net/v1/utils/contact";
            const request_body = {
                "subject":selectedSubject,
                "name":name,
                "email":email,
                "message":message,
                "optin":optingIn
            }
            //console.log(request_body);
            const options = { 
                method: "POST", 
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request_body)
            };

            fetch(resultsUrl, options)
            .then( ( response ) => {
                return response.json(); 
            })
            .then((data) => { 
                if(data.data.rows_inserted === 1) {
                    setFormSent(true);
                    setIsLoading(false);
                    //disable the submit button - prevent resubmissions
                    document.getElementById('form-submit').disabled = true;
                    //clear all fields.
                    setSelectedSubject('');
                    setName('');
                    setEmail('');
                    setMessage('');
                    //setOptIn('');
                    document.getElementById('my-optin').checked = false;
                }
                setFormSending(false);
                //
                /**
                 * figure out how to pass in the results or results setter function from App.js (via props) to update the main array.
                 * Also, when searching for Honda Civic (all years) - there is an internal server error for "Error: Ad Mileage Unit error." --- check the API.
                 */
            })
            .catch((err) => console.error(err));
        }
        
    };
   
    return (
        <Fragment>
            <Header />
            {isLoading && <LoadingSpinner text="Sending..." />}
            <div className='page-content'>
                <div className="wp-block-cover">
                    <span aria-hidden="true" className="wp-block-cover__background has-background-dim"></span>
                    <img decoding="async" className="wp-block-cover__image-background wp-image-80 img-responsive" alt="" src="https://cms.carcaine.net/wp-content/uploads/2022/10/home-bg.jpg" data-object-fit="cover" srcSet="https://cms.carcaine.net/wp-content/uploads/2022/10/home-bg.jpg 1500w, https://cms.carcaine.net/wp-content/uploads/2022/10/home-bg-300x217.jpg 300w, https://cms.carcaine.net/wp-content/uploads/2022/10/home-bg-1024x741.jpg 1024w, https://cms.carcaine.net/wp-content/uploads/2022/10/home-bg-768x556.jpg 768w" sizes="(max-width: 1500px) 100vw, 1500px" width="1500" height="1086" />
                        <div className="wp-block-cover__inner-container">
                            <p></p>
                            <div className="wp-block-image">
                                <figure className="aligncenter size-full">
                                    <img decoding="async" loading="lazy" src="https://cms.carcaine.net/wp-content/uploads/2022/10/logo.png" alt="" className="wp-image-81 img-responsive" srcSet="https://cms.carcaine.net/wp-content/uploads/2022/10/logo.png 364w, https://cms.carcaine.net/wp-content/uploads/2022/10/logo-300x82.png 300w" sizes="(max-width: 364px) 100vw, 364px" width="364" height="99" />
                                </figure>
                            </div>
                            <div style={{height:'10px'}} aria-hidden="true" className="wp-block-spacer"></div>
                            <h2 className="has-text-align-center has-white-color has-text-color has-large-font-size">Contact Us</h2>
                            <div className="wp-block-darvu-blocks-darvu-blocks-10-variable-container-block">
                                <div className="container" style={{width:'660px'}}>
                                    <div className="background-colour-panel">
                                        <p className="has-text-align-center">
                                            <strong>Want us to add your website or a new feature?</strong> <strong><em>Find a problem?</em></strong>
                                        </p>
                                        <p className="has-text-align-center">Let us know, we’ll get back to you as soon as possible!</p>
                                        <div style={{height:'40px'}} aria-hidden="true" className="wp-block-spacer"></div>
                                        <div className="wp-block-contact-form-7-contact-form-selector">
                                            <div role="form" className="wpcf7" id="wpcf7-f111-p85-o1" dir="ltr" lang="en-US">
                                                <div className="screen-reader-response">
                                                    <p role="status" aria-live="polite" aria-atomic="true"></p> 
                                                    <ul></ul>
                                                </div>
                                                <form onSubmit={formSubmitHandler} className="wpcf7-form init mailchimp-ext-0.5.62" noValidate="novalidate" data-status="init">
                                                <div className="cf-row">
                                                    <span className="wpcf7-form-control-wrap" data-name="your-subject">
                                                        <select name="your-subject" id="your-subject" className="wpcf7-form-control wpcf7-select wpcf7-validates-as-required" aria-required="true" aria-invalid="false" onChange={subjectChangeHandler} value={selectedSubject} >
                                                            <option value="">I want to...</option>
                                                            <option value="Report a Problem">Report a Problem</option>
                                                            <option value="Request a Feature">Request a Feature</option>
                                                            <option value="Say Hi">Say Hi</option>
                                                        </select>
                                                    </span>
                                                    <br/>
                                                    <span className="wpcf7-form-control-wrap" data-name="your-name">
                                                        <input type="text" name="your-name" id="your-name" size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Your Name" onChange={nameChangeHandler} value={name} />
                                                    </span>
                                                    <br/>
                                                    <span className="wpcf7-form-control-wrap" data-name="your-email">
                                                        <input type="email" name="your-email" id="your-email" size="40" className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false" placeholder="Your Email" onChange={emailChangeHandler} value={email} />
                                                    </span>
                                                </div>
                                                <div className="cf-row-full">
                                                    <span className="wpcf7-form-control-wrap" data-name="your-message">
                                                        <textarea name="your-message" id="your-message" cols="40" rows="10" className="wpcf7-form-control wpcf7-textarea" aria-invalid="false" placeholder="Your Message" onChange={messageChangeHandler} value={message}></textarea>
                                                    </span>
                                                </div>
                                                <div className="cf-row-full">
                                                    <span className="wpcf7-form-control-wrap" data-name="my-optin">
                                                        <span className="wpcf7-form-control wpcf7-checkbox">
                                                            <span className="wpcf7-list-item first last">
                                                                <label>
                                                                    <input type="checkbox" name="my-optin[]" value="Join our mailing list - you can opt out whenever you want." id="my-optin" onChange={optinChangeHandler} />
                                                                    <span className="wpcf7-list-item-label">Join our mailing list - you can opt out whenever you want.</span>
                                                                </label>
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <p className="fine-print">
                                                        <b>The Fine Print:</b>
                                                        <br/>Joining our mailing list is optional. If you join our mailing list, we will send you news about service and feature updates on the site. We may also send you details about promotions and offers from our partners, which may be of interest to you as a motorist/motoring enthusiast. Please be assured that we <i>really hate</i> spam, so we promise to keep the promotional bits to a minimum. And every mail we send has an unsubscribe link within it - so you really can opt out whenever you want.
                                                    </p>
                                                    <p>
                                                        <input type="submit" value="Submit" id="form-submit" className="wpcf7-form-control has-spinner wpcf7-submit"/>
                                                        {formSending && <span className="wpcf7-spinner"></span>}
                                                        {formSent && <span className='message-success'>Message Sent Successfully</span>}
                                                    </p>
                                                </div>
                                                <div className="wpcf7-response-output" aria-hidden="true"></div>
                                                <p style={{display: 'none !important'}}>
                                                    <span className="wpcf7-form-control-wrap referer-page">
                                                        <input type="hidden" name="referer-page" value="https://www.carcaine.net/" data-value="https://www.carcaine.net/" className="wpcf7-form-control wpcf7-text referer-page" aria-invalid="false"/>
                                                    </span>
                                                </p>
                                            </form>
                                        </div>
                                    </div>
                                    <div style={{height:'60px'}} aria-hidden="true" className="wp-block-spacer"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default About;