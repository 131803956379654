import { Fragment } from "react";

//import "./Result.css";

const SelectOption = (props) => {
  
  return (
    <Fragment>
      <option value={props.id} data-id={props.id}>{props.title}</option>
    </Fragment>
  );
};

export default SelectOption;
