import { Fragment } from 'react';
import {Link, NavLink } from 'react-router-dom';
import headerLogo from '../../assets/img/logo_header.png';
import './Header.css';

const Header = props => {
    return (
        <Fragment>
            <header>
                <div className="container logo-bar">
                    <div className='logo'>
                        <Link to="/">
                            <img src={headerLogo} alt="Carcaine.net" />
                        </Link>
                    </div>
                    <ul className="main-nav">
                        <li><NavLink to="/about">About</NavLink></li>                        
                        <li><NavLink to="/contact">Contact</NavLink></li>
                    </ul>
                </div>
            </header>
        </Fragment>
    );
}

export default Header;