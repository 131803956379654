import { Fragment, useState, useEffect } from "react";//, useRef

import Result from "../../components/UI/Result";
import SearchBarSide from '../../components/UI/SearchBarSide';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import Pagination from '../../components/UI/Pagination';
import UnicornNotice from '../../components/UI/UnicornNotice';

import "./Results.css";

const Results = (props) => {
  //const sortRef = useRef();
  //const sortChangeRef = useRef();

  //const [summaryCar, setSummaryCar] = useState('');donnellydonnellyDonnelly2020
  const [searchAttributes, setSearchAttributes] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  //const [sortOrder, setSortOrder] = useState('');
  
  
  //console.log(props);
  useEffect(() => {
    //console.log(props.results.data.display_vars);
    let searchAttributes = [];
    searchAttributes.push(props.results.data.display_vars.make+" "+props.results.data.display_vars.model);

    if(props.results.data.query_data.from_year !== null && props.results.data.query_data.from_year !== "" && props.results.data.query_data.from_year !== undefined) {
      searchAttributes.push(props.results.data.query_data.from_year+" - "+props.results.data.query_data.to_year);//+" | ";
      //setAttributesCar(attributesCar+" | "+props.results.data.query_data.from_year+" - "+props.results.data.query_data.to_year);
    }

    if(props.results.data.query_data.from_price !== null && props.results.data.query_data.from_price !== "" && props.results.data.query_data.from_price !== undefined) {
      //youSearchedFor += " | <span>Price:</span> "+response.data.query_data.from_price+" - "+response.data.query_data.to_price;
      searchAttributes.push(props.results.data.query_data.from_price+" - "+props.results.data.query_data.to_price); //+" | ";
      //setAttributesCar(attributesCar+" | "+props.results.data.query_data.from_price+" - "+props.results.data.query_data.to_price);
    }

    if(props.results.data.query_data.transmission !== null && props.results.data.query_data.transmission !== "" && props.results.data.query_data.transmission !== undefined) {
      searchAttributes.push(props.results.data.query_data.transmission);
    }

    if(props.results.data.query_data.text !== null && props.results.data.query_data.text !== "" && props.results.data.query_data.text !== undefined) {
      searchAttributes.push(props.results.data.query_data.text);
    }

    setSearchAttributes(" "+searchAttributes.join(" | "));

    //console.log(props.results);

  }, [setSearchAttributes, props]);

  //Handle the sort Filter
  const sortResultsChangeHandler = (event) => {
    //this function works in a very similar way to the pagination handler below
    //get the current search params
    const query_data = props.results.data.query_data;
    
    //inject/replace the sort param
    query_data.sort=event.target.value;
    
    //request a search (first page is fine) - rather than potentially jumping into the middle of paginated results and applying a different sort order to them.
    const resultsUrl = "https://api.carcaine.net/v1/results";
    const options = { 
        method: "POST", 
        cache: "no-cache",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(query_data)
    };

    fetch(resultsUrl, options)
    .then( ( response ) => {
        return response.json(); 
    })
    .then((data) => { 
        props.onSetResultAds(data);
        
    })
    .catch((err) => console.error(err));
    //process results.
  }

  const paginationHandler = (event) => {
    const pageClicked = event.target.getAttribute('data-id')
    
    const resultsUrl = "https://api.carcaine.net/v1/results/page/"+pageClicked;
    const request_body = props.results.data.query_data;
    
    const options = { 
        method: "POST", 
        cache: "no-cache",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request_body)
    };

    fetch(resultsUrl, options)
    .then( ( response ) => {
        return response.json(); 
    })
    .then((data) => { 
        props.onSetResultAds(data);
        
    })
    .catch((err) => console.error(err));
  }
 
  //let counter=0;
  //console.log("Ads: "+props.results.data.ads.length);
  const noAds = props.results.data.ads.length;
  const resultsPanels = props.results.data.ads.map((ad, index) => {

    const adContent = (
      <div className="site-container ad-container" key={Math.random()}>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7919601831039622" crossOrigin="anonymous"></script>
          <ins className="adsbygoogle"
            style={{display:"block"}}
            data-ad-format="fluid"
            data-ad-layout-key="-fb+5w+4e-db+86"
            data-ad-client="ca-pub-7919601831039622"
            data-ad-slot="4695605162"></ins>
          <script>
            (adsbygoogle = window.adsbygoogle || []).push({});
          </script>
        </div>
    );

    const resultContent = (
      <Result
              key={ad.id}
              adID={ad.id}
              make={ad.make}
              model={ad.model}
              title={ad.title}
              site_name={ad.site_name}
              url={ad.url}
              currency={ad.currency}
              date={ad.date}
              description={ad.description}
              engine_size={ad.engine_size}
              fuel_type={ad.fuel_type}
              location={ad.location}
              mileage={ad.mileage}
              mileage_unit={ad.mileage_unit}
              price={ad.price}
              registration={ad.registration}
              second_line={ad.second_line}
              seller_type={ad.seller_type}
              thumbnail={ad.thumbnail}
              transmission={ad.transmission}
              year={ad.year}
            />
    );

    //setup an array of content to return
    const returnArray = [];
    //push the result content to the return array
    returnArray.push(resultContent);
    
    //display a google ad every five ads (after the first ad)
    if(index%5 === 0) {
      returnArray.push(adContent);
    }

    return returnArray;  
});
/*
*/
            // sortOrder={sortOrder} 

  return (
    <Fragment>
      <div id="results-body">
        <div className="left-sidebar">
          <SearchBarSide makes={props.makes} onSetResultAds={props.onSetResultAds} queryData={props.results.data.query_data} setIsLoading={setIsLoading}/>
        </div>
        <div className="results">
          {isLoading && <LoadingSpinner text="Searching..." />}
          <div className="searchSummary topSearchSummary">
            <div className="left"><span><b>You Searched For:</b><br/>{searchAttributes}</span></div>
            <div className="middle">
              <span>{props.results.data.total_ads !== undefined && <b>{props.results.data.total_ads} ads found</b>}</span>
              {props.results.data.total_pages > 1 && <Pagination totalPages={props.results.data.total_pages} totalAds={props.results.data.total_ads} currentPage={props.results.data.current_page} onPaginationClickHandler={paginationHandler} />}
            </div>
            <div className="right select">
              <select id="sort-results" onChange={sortResultsChangeHandler} title="Sort By" >
                <option value="">Most Recent Ad</option>
                <option value="yeardsc">Youngest</option>
                <option value="yearasc">Oldest</option>
                <option value="priceasc">Lowest Price</option>
                <option value="pricedsc">Highest Price</option>
                <option value="mileageasc">Lowest Mileage</option>
                <option value="mileagedsc">Highest Mileage</option>
              </select>
            </div>
            
          </div>
          {resultsPanels}
          {noAds === 0 && <UnicornNotice />}
          <div className="searchSummary">
            <span><b>You Searched For:</b>&nbsp;{searchAttributes}</span>
            <span>{props.results.data.total_ads !== undefined && <b>{props.results.data.total_ads} ads found.</b>}</span>
            {props.results.data.total_pages > 1 && <Pagination totalPages={props.results.data.total_pages} totalAds={props.results.data.total_ads} currentPage={props.results.data.current_page} onPaginationClickHandler={paginationHandler} />}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Results;
