import { Fragment, useState } from "react";

import SelectOption from './SelectOption';

import "./SearchBar.css";

const SearchBar = (props) => {

    const [models, setModels] = useState([]);
    const [selectedMake, setSelectedMake] = useState('');
    const [selectedModel, setSelectedModel] = useState('');
    const [selectedFromYear, setSelectedFromYear] = useState('');
    const [selectedToYear, setSelectedToYear] = useState('');
    const [selectedFromPrice, setSelectedFromPrice] = useState('');
    const [selectedToPrice, setSelectedToPrice] = useState('');
    const [selectedTransmission, setSelectedTransmission] = useState('');
    //const [enteredText, setEnteredText] = useState('');
    
    //populate the makes dropdown - makes are passed in via props (from App.js)
    //populate the two years dropdowns
    const year_array = [];
    
    for(let year = new Date().getFullYear(); year >= 1940; year=year-1) {
        year_array.push(year);
    }		
    //console.log(year_array);


    //populate the two prices dropdowns
    const price_array = [];
    price_array.push(500);
    for(let price = 1000; price <= 500000; price=price+1000) {
        price_array.push(price);

        if(price >= 100000) {
            price = price+24000;
        }
        else if(price >= 50000) {
            price = price+9000;
        }
        else if(price >= 35000) {
            price = price+4000;
        }
    }	
    //console.log(price_array);


    //change handler for the makes dropdown - fetch models
    const makeChangeHandler = (event) => {
        //console.log("Make Changed");
        setSelectedMake(event.target.value);
        const formValue = event.target.value;

        if(formValue === "") { //if the from price has not been selected
            document.getElementById('model').disabled = true;
        }
        else {
            //document.getElementById('model').disabled = false;

            //get the models for the relevant make
            //setIsLoading(true);

            const modelsUrl = "https://api.carcaine.net/v1/models";
            const modelsDropdown = document.querySelector( "#model" );
    
    
            const request_body = {
                make_id: formValue
            };
    
            const options = { 
                method: "POST", 
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request_body)
            };
          
            fetch(modelsUrl, options)
            .then( ( response ) => {
                return response.json(); 
            })
            .then((data) => { 
                setModels(data.data.models);
                modelsDropdown.disabled = false;
            })
            .catch((err) => console.error(err));
           
        }
    


        
    }

    //change handler for the models dropdown
    const modelChangeHandler = (event) => {
        //console.log("Model Changed");
        setSelectedModel(event.target.value);
    }


    //change handler for from year dropdown
        //remove/disable all other older years from the To Year dropdown
        //enable the To Year Dropdown
    const fromYearChangeHandler = (event) => {
        setSelectedFromYear(event.target.value);
        const formValue = event.target.value;
        
        //if the from year dropdown was changed back to "Choose Year", then reset the form
        if(formValue === "") { //if the from year has not been selected
            document.getElementById('yearto').disabled = true;
            //document.getElementById("yearto").selectedIndex = 0;
            //reset the selected To Year
            setSelectedToYear("");
        }
        else {
            //else enable the TO year dropdown and disable years previous to the selected from year
            document.getElementById('yearto').disabled = false;
            document.querySelectorAll("#yearto option").forEach(opt => {
                opt.disabled = false;
            });
        }

        //disable all years that are before the selected from year.
        document.querySelectorAll("#yearto option").forEach(opt => {
            if((parseInt(opt.value, 10) < parseInt(formValue, 10))) {// || (opt.value === "") 
                opt.disabled = true;
            }
        });
        //reset the selected To Year
        setSelectedToYear("");

    }

    const toYearChangeHandler = (event) => {
        setSelectedToYear(event.target.value);
    }

    //change handler for from price dropdown
        //remove/disable all other lower prices from the To Price dropdown
        //enable the To Price Dropdown
    const fromPriceChangeHandler = (event) => {
        setSelectedFromPrice(event.target.value);
        const formValue = event.target.value;
        
        //console.log("form value "+formValue )
        //if the from price dropdown was changed back to "Choose Price", then reset the form
        if(formValue === "") { //if the from price has not been selected
            document.getElementById('priceto').disabled = true;
            //document.getElementById("priceto").selectedIndex = 0;
            //reset the selected To Price
            setSelectedToPrice("");
        }
        else {
            //else enable the TO price dropdown and disable prices below the selected from price
            document.getElementById('priceto').disabled = false;
            document.querySelectorAll("#priceto option").forEach(opt => {
                opt.disabled = false;
            });
        }

        //disable all prices that are below the selected from price.
        document.querySelectorAll("#priceto option").forEach(opt => {
            if((parseInt(opt.value, 10) < parseInt(formValue, 10)) || (opt.value === "") ) {
                opt.disabled = true;
            }
        });
        //reset the selected To Price
        setSelectedToPrice("");
    }
    
    const toPriceChangeHandler = (event) => {
        setSelectedToPrice(event.target.value);
    }

    const transmissionChangeHandler = (event) => {
        setSelectedTransmission(event.target.value);
    }



    //submitHandler
        //validate the form
        //send the async query to the api
        //shift the layout
    const formSubmitHandler = (event) => {

        event.preventDefault(); 
        let errorFree = true;
        if(selectedMake === "") {
            //console.log("make is empty");
            document.getElementById('make').classList.add("error");
            errorFree=false;
        }
        else {
            document.getElementById('make').classList.remove("error");
        }
        if(selectedModel === "") {
            //console.log("model is empty");
            document.getElementById('model').classList.add("error");
            errorFree=false;
        }
        else {
            document.getElementById('model').classList.remove("error");
        }
        if(selectedFromYear !== "" && selectedToYear === "") {
            //console.log("To Year is empty");
            document.getElementById('yearto').classList.add("error");
            errorFree=false;
        }
        else {
            document.getElementById('yearto').classList.remove("error");
        }
        if(selectedFromPrice !== "" && selectedToPrice === "") {
            //console.log("To Price is empty");
            document.getElementById('priceto').classList.add("error");
            errorFree=false;
        }
        else {
            document.getElementById('priceto').classList.remove("error");
        }
       /*
        if($("#search_form input[type=checkbox]:checked").length == 0) {
            error_free=false;

            //need to add some logic here if no country is selected. Fall back on IE?? Or Geo located option?
        }*/
        
        
        if (errorFree){
            props.setIsLoading(true);
            //console.log("kick off the search");
            const resultsUrl = "https://api.carcaine.net/v1/results";
            const request_body = {
                "make":selectedMake,
                "model":selectedModel,
                "from_year":selectedFromYear,
                "to_year":selectedToYear,
                "from_price":selectedFromPrice,
                "to_price":selectedToPrice,
                "transmission":selectedTransmission,
                "location":"IRL"
            }
            //console.log(request_body);
            const options = { 
                method: "POST", 
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request_body)
            };

            fetch(resultsUrl, options)
            .then( ( response ) => {
                return response.json(); 
            })
            .then((data) => { 
                //console.log(data);
                props.onSetResultAds(data);
                props.setIsLoading(false);
                /**
                 * figure out how to pass in the results or results setter function from App.js (via props) to update the main array.
                 * Also, when searching for Honda Civic (all years) - there is an internal server error for "Error: Ad Mileage Unit error." --- check the API.
                 */
                //setResults(data.data.models);
                //modelsDropdown.disabled = false;
            })
            .catch((err) => console.error(err));
        }
    }
   // console.log (props);

   return (
        <Fragment>
            <div id="search-bar">
                <form id="search_form" onSubmit={formSubmitHandler}>
                    <div id="make-model">
                    <div className="select">
                        <select id="make" name="make" className="" onChange={makeChangeHandler} value={selectedMake}>
                        <option value="">Choose Make</option>
                        <optgroup label="Most searched for">
                           
                            {   //console.log(props.makes)
                                props.makes.map(make => {
                                //the only way to carry out logic inside a map function is to return the output via a ternary if statement as far as I can make out.
                                return make.most_searched === 1 ? 
                                    <SelectOption key={Math.random()} id={make.id} data-id={make.id} title={make.title} />
                                : null
                                   
                                }) 
                            }
                        </optgroup>
                        <optgroup label="All makes">
                            {props.makes.map(make => ( 
                                <SelectOption key={make.id} id={make.id} data-id={make.id} title={make.title} />
                            ))}
                        </optgroup>
                        </select>
                    </div>
                    <div className="select">
                        <select id="model" name="model" disabled="disabled" onChange={modelChangeHandler} value={selectedModel}>
                        <option value="">Choose Model</option>
                        {models.map(model => ( 
                            <SelectOption key={model.id} id={model.id} data-id={model.id} title={model.title} />
                        ))}
                        </select>
                    </div>
                    </div>
                    <div id="price-year">
                    <div className="select">
                        <select id="yearfrom" name="yearfrom" onChange={fromYearChangeHandler} value={selectedFromYear}>
                            <option value="">From Year</option>
                            {year_array.map(year => ( 
                                <SelectOption key={year} id={year}  data-id={year} title={year} />         
                            ))}
                        </select>
                    </div>
                    <div className="select">
                        <select id="yearto" name="yearto" disabled="disabled" onChange={toYearChangeHandler} value={selectedToYear}>
                            <option value="">To Year</option>
                            {year_array.map(year => ( 
                                <SelectOption key={year} id={year} data-id={year} title={year} />         
                            ))}  
                        </select>
                    </div>
                    <div className="select">
                        <select id="pricefrom" name="pricefrom" onChange={fromPriceChangeHandler} value={selectedFromPrice}>
                            <option value="">From Price</option>
                            {price_array.map(price => ( 
                                <SelectOption key={price} id={price} data-id={price} title={price.toLocaleString("en-IE")} />
                                    
                            ))}
                        </select>
                    </div>
                    <div className="select">
                        <select id="priceto" name="priceto" disabled="disabled" onChange={toPriceChangeHandler} value={selectedToPrice}>
                            <option value="">To Price</option>
                            {price_array.map(price => ( 
                                <SelectOption key={price} id={price} data-id={price} title={price.toLocaleString("en-IE")} />
                                    
                            ))}
                        </select>
                    </div>

                    <div className="select" onChange={transmissionChangeHandler} value={selectedTransmission}>
                        <select id="transmission" name="transmission">
                        <option value="">Transmission</option>{" "}
                        <option value="any">Any</option>{" "}
                        <option value="manual">Manual</option>{" "}
                        <option value="automatic">Automatic</option>
                        </select>
                    </div>
                    </div>
                    <input
                    type="submit"
                    value="Find your new car!"
                    id="search_submit"
                    />
                </form>
            </div>
        </Fragment>
    );
};

export default SearchBar;
