import React, {useState, useEffect, useCallback} from 'react';


import "./StatusTable.css";

const StatusTable = () => {

    const [locationStatus, setLocationStatus] = useState(Array);

    const fetchLocationsHandler = useCallback(async () => {
        //setIsLoading(true);
    
        const statusUrl = "https://api.carcaine.net/v1/utils/status";
        const options = { 
            method: "GET", 
            cache: "no-cache"
        };
        
        fetch(statusUrl, options)
            .then( ( response ) => {
                return response.json(); 
            })
            .then((data) => { 
                //console.log(data.data.sites);
                setLocationStatus(data.data.sites);
            })
            .catch((err) => console.error(err));

            
        //setIsLoading(false);
      }, []);

    useEffect(() => {
        fetchLocationsHandler();
        //console.log("locationStatus");
        //console.log(locationStatus);
    },[fetchLocationsHandler]);
    
    return (
        <table id="status-table">
        <thead>
          <tr>
            <th>Site</th>
            <th>Country</th>
            <th>Status</th>
            <th>Integration Level</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
          <th>Site</th>
            <th>Country</th>
            <th>Status</th>
            <th>Integration Level</th>
          </tr>
        </tfoot>
        <tbody>
            {locationStatus.map((site) => (
                <tr key={Math.random()}>
                    <td><a href={site.site_url} target="_blank" rel="noreferrer">{site.site_name}</a></td>
                    <td>{site.location_code === "IRL" ? ("Ireland"):("UK") }</td>
                    <td style={site.site_integration_status === "Active" ? ({color:'green'}) : ({color:'red'})}>{site.site_integration_status}</td>
                    <td>{site.site_integration_notes}</td>
                </tr>
            ))}
        </tbody>
      </table>
            
    );
};

export default StatusTable;