import { Fragment } from 'react';
import {NavLink } from 'react-router-dom';
import './Footer.css';

const Header = props => {
    let year =  new Date().getFullYear();
    //console.log(year);
    return (
        <Fragment>
            <footer>
                <div className="container">
                    <div className="footer-nav">
                        <ul id="footer-menu" className="menu">
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-165">
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-180">
                                <NavLink to="/frequently-asked-questions">FAQ</NavLink>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-105 current_page_item menu-item-168">
                                <NavLink to="/changelog" aria-current="page">Status &amp; Changelog</NavLink>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-167">
                                <NavLink to="/contact">Contact</NavLink>
                            </li>
                        </ul>        
                    </div>
                    <div className="footer-copyright">
                        <div>Copyright Carcaine.net &copy;{year}</div>
                    </div>
                </div>
                
            </footer>
        </Fragment>
    );
}

export default Header;