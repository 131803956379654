import React from "react";
import "./LoadingSpinner.css";

const LoadingSpinner = (props) => {
//export default function LoadingSpinner() {
  
  return (
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
      <p>{props.text}</p>
    </div>
  );
}

export default LoadingSpinner;